.bullish {
  background: linear-gradient(
      214.84deg,
      rgba(255, 205, 75, 0.73) 3.73%,
      rgba(205, 164, 111, 0) 98.85%
    ),
    linear-gradient(0deg, #d2883e, #d2883e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bullish-background {
  background: linear-gradient(
      214.84deg,
      rgba(255, 205, 75, 0.73) 3.73%,
      rgba(205, 164, 111, 0) 98.85%
    ),
    linear-gradient(0deg, #d2883e, #d2883e);
}

.bullish-svg {
  color: rgba(255, 205, 75, 0.73) !important;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal !important;
}

ul {
  list-style: square inside url("bullet.svg");
  padding-left: 0px !important;
  text-indent: -55px; /* key property */
  margin-left: 55px; /* key property */
}

.roadmap-square-bg {
  background: #191919;
  padding-top: 16px;
  padding-bottom: 8px;
  z-index: 999;
  position: absolute;
}

.roadmap-line {
  position: absolute;
  width: 2px;
  top: -16px;
  bottom: 0px;
  left: 58px;
  z-index: 997;
  background: #272727;
}

.roadmap-line-done {
  position: absolute;
  width: 8px;
  top: -48px;
  height: 222px;
  left: 55px;
  z-index: 998;
  animation-duration: 3s;
  animation-name: roadmapProgress;
  background: linear-gradient(
      214.84deg,
      rgba(255, 205, 75, 0.73) 3.73%,
      rgba(205, 164, 111, 0) 98.85%
    ),
    linear-gradient(0deg, #d2883e, #d2883e);
}

@keyframes roadmapProgress {
  from {
    height: 0;
  }
  to {
    height: 222px;
  }
}

.social-stack {
  margin-top: 64px;
  justify-content: flex-start;
  align-items: center;
}

.social-stack a {
  margin-right: 16px;
}

button {
  text-transform: capitalize !important;
}

.issue-img {
  position: absolute;
  top: -48px;
  background: #131313;
  border: 1px solid #2b2b2b;
  width: 96px;
  height: 96px;
  border-radius: 100%;
}

.issue-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.draw {
  animation: draw 2s ease-in-out infinite;
  transform-origin: center top;
}

@keyframes draw {
  25% {
    transform: scaleY(0.75);
  }

  50% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.75);
  }
  100% {
    transform: scaleY(1);
  }
}

.affix {
  position: fixed;
  top: 96px;
}

.outlined-btn {
  border: 1px solid #1f1f1f !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 16px 32px !important;
  color: #eeecea !important;
}

.contained-btn {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 16px 32px !important;
  background: linear-gradient(
      214.84deg,
      rgba(255, 205, 75, 0.73) 3.73%,
      rgba(205, 164, 111, 0) 98.85%
    ),
    #d2883e !important;
  border-radius: 5px !important;
  color: #131313 !important;
  font-weight: bold !important;
}

.menu-btn {
  padding: 8px 16px !important;
}

.contained-btn svg {
  vertical-align: middle;
}

.affix-active {
  border: 1px solid #d2883e !important;
  transition: border 1000ms !important;
}

.affix-active h6 {
  background: linear-gradient(
      214.84deg,
      rgba(255, 205, 75, 0.73) 3.73%,
      rgba(205, 164, 111, 0) 98.85%
    ),
    linear-gradient(0deg, #d2883e, #d2883e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
